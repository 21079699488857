<template>
  <div class="login" id='login'>
    <div class="title">
      手机号注册/登录
      <span>未注册过的手机号码将自动注册</span>
    </div>
    <div class="from-box">
      <van-form>
        <div class="from-title">手机号码</div>
        <van-field
          v-model="mobile"
          clearable
          οninput="if(value.length>11)value=value.slice(0,11)"
          type="number"
          maxlength="11"
          placeholder="请输入手机号"
        />
<!--        :rules="[{ required: true, message: '请输入手机号' }]"-->
        <div class="from-title magrin-top-30">验证码</div>
        <van-field
            v-model="code"
            center
            clearable
            placeholder="请输入短信验证码"
          >
        <template #button>
          <van-button size="small" v-show="show" type="default" @click="getCode">获取验证码</van-button>
          <van-button size="small" v-show="!show" type="default">{{count}}秒后重新获取</van-button>
        </template>
      </van-field>
        <van-checkbox v-model="radio" icon-size="14px" class="radio-btn">已阅读并同意<span><router-link to='/userAgreement'>用户协议</router-link></span>和<span><router-link to='/privacyAgreement'>隐私政策</router-link></span></van-checkbox>
        <van-button type="info" class="magrin-top-30" round  size="large"  @click="onSubmit">登录</van-button>
        <van-button type="info" class="magrin-top-30 blue-btn" round  size="large"  @click="noSubmit">暂不登录</van-button>
      </van-form>
    </div>
    <van-overlay v-if="loadingShow" >
       <van-loading color="#1989fa" />
   </van-overlay>
   
  </div>
</template>

<script>
 import { getUserSendcode,login,sessionBind } from '@/api'
import Vue from 'vue'
import { Form ,Field,Button,DatetimePicker,Popup,RadioGroup, Radio,Toast,Loading} from 'vant';
Vue.use(Form);
Vue.use(Toast);
Vue.use(Loading)
export default {
  name: 'login',
  data() {
    return {
      mobile:'',
      code:'',
      loadingShow:false,
      show:true,
      count:"",
      timer: null,
      radio:""
    }
  },
   components: {

   },
  created() {
    //判断是否有token
    if(this.$store.getters.token){
      this.$router.push({path:'/'});
    }
    if( sessionStorage.getItem('loginAll')){
      let loginAll  = JSON.parse(sessionStorage.getItem('loginAll'))
      this.mobile = loginAll.mobile
      this.code = loginAll.code
      this.radio = loginAll.radio
    }
  },
  methods: {
    getCode() {
      if(this.mobile == ''){
        Toast('请输入手机号');
      }else{
        this.loadingShow = true
        getUserSendcode(this.mobile).then((res) => {
          this.loadingShow = false
           Toast('验证码正在发送，请稍后');
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000)
          }
        })
      }
      
    },
    onSubmit(){
      if(this.mobile == ''){
        Toast('请输入手机号');
        return
      }
      if(this.code ==''){
        Toast('请输入短信验证码');
        return
      }
      if(this.radio == ''){
        Toast('请阅读并同意用户协议和隐私政策');
        return
      }
      let query={
       mobile: this.mobile,
       code:this.code
      }
      login(query).then((res) => {
          this.$store.commit('SET_TOKEN', res)
          this.loadingShow = false
          if(this.$store.getters.session){
            let box ={
              session_id:this.$store.getters.session
            }
            sessionBind(box).then((res) => {
              console.log(res)
              this.$store.commit('SET_SESSION', res)
            })
          }
          Toast('登录成功');
        sessionStorage.removeItem('loginAll')
        this.$router.push({path:'/caseList'});
        //this.$router.push({path:'/'});
        })
    },
    noSubmit(){
      this.$router.push({path:'/caseUpload'});
      sessionStorage.removeItem('loginAll')
    }
  },
  beforeRouteLeave(to,from,next){
    if(to.name == 'userAgreement' || to.name == 'privacyAgreement'){
      let loginAll = {
        mobile:this.mobile,
        code:this.code,
        radio:this.radio
      }
      sessionStorage.setItem('loginAll',JSON.stringify(loginAll));
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.jpg');
  background-size:100% 100% ;
  position: fixed;
  overflow-y:auto;
  padding-top:1.95rem;
  .title{
    font-size: .34rem;
    font-weight: 500;
    padding: .6rem;
    span{
      display: block;
      font-size: .24rem;
      color: #BDC1C8;
    }
  }
  .from-box{

    margin: 0 .6rem;
  }
  .from-title{
    font-size: .28rem;
    font-weight: 500;
    margin-bottom: .3rem;
  }
  .magrin-top-30{
    margin-top: .6rem;
  }
  
}
.blue-btn{
  background: #fff;
  color: #2970FF;
  border: 1px solid #fff;
  box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
}
.radio-btn{
  font-size: .24rem;
  margin-top: .2rem;
  span{
    color: #2970FF;
  }
}
</style>
<style>
#login .van-cell{
  padding: 0 0 10px 0;
  background: none;
}
#login .van-field__control{
  background: none;
}
#login .van-cell:after{
  left: 0!important;
}
</style>