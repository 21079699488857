// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login[data-v-37dfd6fc] {\n  width: 100%;\n  height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  position: fixed;\n  overflow-y: auto;\n  padding-top: 1.95rem;\n}\n.login .title[data-v-37dfd6fc] {\n    font-size: .34rem;\n    font-weight: 500;\n    padding: .6rem;\n}\n.login .title span[data-v-37dfd6fc] {\n      display: block;\n      font-size: .24rem;\n      color: #BDC1C8;\n}\n.login .from-box[data-v-37dfd6fc] {\n    margin: 0 .6rem;\n}\n.login .from-title[data-v-37dfd6fc] {\n    font-size: .28rem;\n    font-weight: 500;\n    margin-bottom: .3rem;\n}\n.login .magrin-top-30[data-v-37dfd6fc] {\n    margin-top: .6rem;\n}\n.blue-btn[data-v-37dfd6fc] {\n  background: #fff;\n  color: #2970FF;\n  border: 1px solid #fff;\n  box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);\n}\n.radio-btn[data-v-37dfd6fc] {\n  font-size: .24rem;\n  margin-top: .2rem;\n}\n.radio-btn span[data-v-37dfd6fc] {\n    color: #2970FF;\n}\n", ""]);
// Exports
module.exports = exports;
